import React from "react";
import SearchResultPage from "../../../templates/search-results-map-template";
// import {
//     parseSearchUrl,
//     savedSearchParams,
//     propertyH1
// } from "../../../Components/common/site/utils";

const SearchResultLettingsMap = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    // const searchParams = parseSearchUrl("sales", fullpath);

    return (
        <React.Fragment>
            <SearchResultPage 
                locationname="/properties-map/to-rent/" 
                pcategorytype="residential" 
                ptype="lettings" 
                ptypetag="to-rent" 
                pstatustype= {['To Let']} 
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                {...props}
                location={location}
            />
        </React.Fragment>
    );
};

export default SearchResultLettingsMap;
